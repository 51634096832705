<template>
  <button
    class="btn btn-primary mr-1"
    data-toggle="collapse"
    data-target="#form-import"
  >
    <i class="fas fa-upload"></i> Import
  </button>
</template>

<script>
import { apiPost } from '../../services/api';
export default {
  name: "Import",
  methods: {
    importData() {
    },
  },
};
</script>